























































import Loading from '@keicreations/sapphire-vue/components/ui/Loading.vue'
import { AxiosError } from 'axios'

import HttpStatusCode from '@/enums/HttpStatusCodes.ts'
import TypedVue from '@/config/vue.ts'

interface IData {
    busy: boolean
    email?: string
    password?: string
}

export default TypedVue.extend({
    name: 'Login',
    components: { Loading },

    data(): IData {
        return {
            busy: false,
            email: undefined,
            password: undefined,
        }
    },

    computed: {
        loggedIn(): boolean {
            return this.$store.state.user.user !== null
        },
    },

    methods: {
        async onSubmit(event: Event): Promise<void> {
            this.busy = true
            event.preventDefault()

            try {
                await this.$store.dispatch('auth/login', {
                    username: this.email,
                    password: this.password,
                })
                this.$bvToast.toast('Je bent nu ingelogd!', {
                    title: 'Ingelogd',
                    variant: 'success',
                })
            } catch (error) {
                this.handleError(error)
            } finally {
                this.busy = false
            }
        },

        handleError(error: AxiosError): void {
            if (error.response === undefined) {
                this.$bvToast.toast('An unknown error occurred', {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                })
            } else {
                if (error.response.status < HttpStatusCode.MultipleChoices) {
                    return
                }

                if (error.response.status === HttpStatusCode.NotFound) {
                    this.$bvToast.toast(String(error.response.statusText), {
                        title: 'Failed',
                        variant: 'warning',
                        autoHideDelay: 5000,
                    })
                } else {
                    this.$bvToast.toast(String(error.response.statusText), {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000,
                    })
                }
            }
        },
    },
})
