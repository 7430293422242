






















































































import HttpStatusCodes from '@/enums/HttpStatusCodes.ts'
import TypedVue from '@/config/vue.ts'
import IMediaObject from '@/types/api/IMediaObject.ts'

interface IData {
    currentPage: number
    totalItems?: number
    itemsPerPage: number
    objects: Array<IMediaObject>
    selectedObjectIris: Array<string>
    selectedFilesForUpload: Array<File>
    uploading: boolean
}

export default TypedVue.extend({
    name: 'FilePickerModal',

    computed: {
        isActive: {
            get(): boolean {
                return this.$store.state.filePicker.isModalActive
            },

            set(isActive: boolean): void {
                this.$store.state.filePicker.isModalActive = isActive
            },
        },

        isMultiple(): boolean {
            return this.$store.state.filePicker.isMultiple
        },
    },

    data(): IData {
        return {
            objects: [],
            selectedObjectIris: [],
            selectedFilesForUpload: [],
            uploading: false,
            currentPage: 1,
            totalItems: undefined,
            itemsPerPage: 25,
        }
    },

    methods: {
        onClose(): void {
            this.currentPage = 1
            this.selectedObjectIris = []
        },

        async confirmDelete(item: IMediaObject): Promise<void> {
            let shouldDelete = false

            try {
                shouldDelete = await this.$bvModal.msgBoxConfirm(
                    'Weet je zeker dat je dit wilt verwijderen?',
                    {
                        okTitle: 'Ja, ik weet het heel zeker',
                        okVariant: 'danger',
                        cancelTitle: 'Nee, laat maar zitten',
                        centered: true,
                    }
                )
            } catch {
                // shouldDelete is initialized at false
            }

            if (shouldDelete) {
                try {
                    await this.$api.authenticated().delete(item['@id'])

                    await this.refresh()

                    this.$bvToast.toast('Item verwijderd!', {
                        title: 'Verwijderd',
                        variant: 'success',
                    })
                } catch (error) {
                    const message =
                        error?.response?.status === HttpStatusCodes.Conflict
                            ? 'Kon dit verwijderen, omdat deze afbeelding nog ergens gebruikt wordt.'
                            : 'Ik kon dit niet verwijderen. Vraag Alex om hulp.'

                    this.$bvToast.toast(message, {
                        title: 'Kon niet verwijderen',
                        variant: 'danger',
                    })
                }
            }
        },

        onClickObject(objectIri: string): void {
            if (this.isSelected(objectIri)) {
                this.removeFromSelection(objectIri)
            } else if (this.isMultiple) {
                this.selectedObjectIris.push(objectIri)
            } else {
                this.selectedObjectIris = [objectIri]
            }
        },

        async onClickOk(): Promise<void> {
            if (!this.$store.state.filePicker.callback) {
                return
            }

            this.$store.commit('filePicker/setIsModalActive', false)

            const files = this.isMultiple
                ? this.selectedObjectIris
                : this.selectedObjectIris[0]

            await this.$store.state.filePicker.callback(files)
        },

        async refresh(): Promise<void> {
            const parameters = new URLSearchParams()

            parameters.append('order[createdAt]', 'DESC')
            parameters.append('itemsPerPage', String(this.itemsPerPage))
            parameters.append('page', String(this.currentPage))
            parameters.append('order[id]', 'DESC')

            const response = await this.$api
                .authenticated()
                .get(`/api/media_objects?${parameters.toString()}`)

            this.objects = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
        },

        onClickUpload(): void {
            this.$bvModal.show('file-upload-modal')
        },

        isSelected(objectIri: string): boolean {
            return this.selectedObjectIris.includes(objectIri)
        },

        removeFromSelection(objectIri: string): void {
            this.selectedObjectIris = this.selectedObjectIris.filter(
                (selectedObjectIri): boolean => selectedObjectIri !== objectIri
            )
        },

        async uploadFiles(event: Event): Promise<void> {
            try {
                event.preventDefault()
                this.uploading = true
                await Promise.all(
                    this.selectedFilesForUpload.map(
                        async (file): Promise<void> => {
                            const formData = new FormData()

                            formData.append('file', file)

                            await this.$api
                                .authenticated()
                                .post('/api/media_objects', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    },
                                })
                        }
                    )
                )
            } catch {
                this.$bvToast.toast(
                    'Tenminste 1 bestand kon niet geüpload worden. Toegestane formaten zijn JPEG en PNG.',
                    {
                        title: 'Uploadfout',
                        variant: 'danger',
                    }
                )
            } finally {
                this.selectedFilesForUpload = []
                this.selectedObjectIris = []
                await this.refresh()
                this.$bvModal.hide('file-upload-modal')
                this.uploading = false
            }
        },
    },

    watch: {
        async currentPage(): Promise<void> {
            await this.refresh()
        },
    },
})
