import { MutationTree } from 'vuex'

import IState from '@/store/modules/filePicker/IState.ts'

const mutations: MutationTree<IState> = {
    setIsModalActive(state: IState, payload): void {
        state.isModalActive = payload
    },

    setIsMultiple(state: IState, payload): void {
        state.isMultiple = payload
    },

    setCallback(state: IState, payload): void {
        state.callback = payload
    },
}

export default mutations
