









import { PropType } from 'vue'

import IMediaObject from '@/types/api/IMediaObject.ts'
import TypedVue from '@/config/vue.ts'
import FilePickerModal from '@/components/site/modals/FilePickerModal.vue'
// @ts-expect-error -- it's a fucking image, fuck off
import placeholder from '@/assets/img/placeholder.jpg'

export default TypedVue.extend({
    name: 'EditableImage',
    components: { FilePickerModal },

    computed: {
        loggedIn(): boolean {
            return this.$store.state.user.user
        },

        placeholder(): string {
            return placeholder
        },
    },

    methods: {
        async onClickImage(): Promise<void> {
            if (!this.loggedIn) {
                return
            }

            await this.$store.dispatch('filePicker/pickFiles', {
                callback: (iri: string): void => {
                    this.$emit('update', iri)

                    const unwatch = this.$watch('content', (): void => {
                        this.$bvToast.toast('De wijziging is opgeslagen.', {
                            title: 'Wijziging doorgevoerd.',
                            variant: 'success',
                        })
                        unwatch()
                    })
                },
            })
        },
    },

    props: {
        size: {
            type: String as PropType<'small' | 'medium' | 'large' | 'original'>,
            required: true,
        },

        content: {
            type: Object as PropType<IMediaObject>,
            default: undefined,
        },
    },
})
