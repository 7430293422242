


























import TypedVue from '@/config/vue.ts'

interface IData {
    editing: boolean
    unwatch?: Function
    offset: string
}

export default TypedVue.extend({
    name: 'EditableText',

    data(): IData {
        return {
            editing: false,
            unwatch: undefined,
            offset: '0px',
        }
    },

    computed: {
        value(): any {
            return this.$refs.value
        },

        loggedIn(): boolean {
            return this.$store.state.user.user
        },
    },

    methods: {
        focus(): void {
            this.editing = true
        },

        save(event?: Event): void {
            if (event) {
                event.preventDefault()
            }

            if (this.unwatch) {
                this.unwatch()
            }

            if (this.value.textContent.trim() === this.content) {
                this.value.blur()
                this.editing = false
            }

            this.$emit('update', this.value.textContent.trim())

            this.unwatch = this.$watch('content', (): void => {
                this.$bvToast.toast('De wijziging is opgeslagen.', {
                    title: 'Wijziging doorgevoerd.',
                    variant: 'success',
                })
                this.unwatch!()

                this.value.blur()
                this.editing = false
            })
        },

        blur(): void {
            this.value.textContent = this.content
            this.editing = false
        },

        keydown(event: KeyboardEvent): void {
            if (event.key === 'Enter') {
                event.preventDefault()

                if (this.multiline && !event.ctrlKey) {
                    document.execCommand('insertLineBreak')

                    return
                }

                this.save()
            } else if (event.key === 'Escape') {
                this.value.textContent = this.content
                this.value.blur()
                this.editing = false
            } else {
                // Do the usual -- regular input
            }
        },
    },

    watch: {
        content(content): void {
            // Have to set this here because the element will not listen to a new value when the textContent has been set once externally
            this.value.textContent = content
        },
    },

    props: {
        element: {
            type: String,
            required: true,
        },

        multiline: {
            type: Boolean,
            default: false,
        },

        content: {
            type: String,
            default: undefined,
        },
    },
})
