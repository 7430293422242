






























































import Navigation from '@/components/site/layout/Navigation.vue'
import TypedVue from '@/config/vue.ts'
import Hero from '@/components/site/sections/Hero.vue'
import Portfolio from '@/components/site/sections/Portfolio.vue'
import About from '@/components/site/sections/About.vue'
import Contact from '@/components/site/sections/Contact.vue'
import Blog from '@/components/site/sections/Blog.vue'
import FilePickerModal from '@/components/site/modals/FilePickerModal.vue'
import Maintenance from '@/components/Maintenance.vue'

interface IData {
    isLoading: boolean
}

export default TypedVue.extend({
    name: 'SiteLayout',

    data(): IData {
        return {
            isLoading: true,
        }
    },

    components: {
        Maintenance,
        Portfolio,
        About,
        Contact,
        Hero,
        Blog,
        Navigation,
        FilePickerModal,
    },

    async created(): Promise<void> {
        try {
            await this.$store.dispatch('config/loadSettings')
            await this.$store.dispatch('sections/fetchAll')
        } catch {
            this.$bvToast.toast(
                'The website could not be loaded. Please try again later.',
                {
                    title: 'Site kon niet worden opgehaald',
                    variant: 'danger',
                }
            )
        } finally {
            this.isLoading = false
        }
    },

    computed: {
        loggedIn(): boolean {
            return this.$store.state.user.user
        },

        isMaintenanceModeActive(): boolean {
            return this.$store.state.config.isMaintenanceModeActive
        },

        facebookUrl(): string {
            return process.env.VUE_APP_FACEBOOK_URL
        },

        altFacebookUrl(): string {
            return process.env.VUE_APP_ALT_FACEBOOK_URL
        },

        instagramUrl(): string {
            return process.env.VUE_APP_INSTAGRAM_URL
        },
    },
})
