
































import TypedVue from '@/config/vue.ts'
import EditableSectionText from '@/components/site/editables/EditableSectionText.vue'
import EditableSectionImage from '@/components/site/editables/EditableSectionImage.vue'

export default TypedVue.extend({
    name: 'About',

    components: { EditableSectionImage, EditableSectionText },
})
