















































































import { PropType } from 'vue'

import IBlogItem from '@/types/api/IBlogItem.ts'
import TypedVue from '@/config/vue.ts'
import EditableImage from '@/components/site/editables/EditableImage.vue'
import EditableText from '@/components/site/editables/EditableText.vue'

interface IData {
    showFullContent: boolean
    itemSummaryMaxChars: number
}

export default TypedVue.extend({
    name: 'BlogItem',

    data(): IData {
        return {
            showFullContent: false,
            itemSummaryMaxChars: 250,
        }
    },

    components: { EditableImage, EditableText },

    props: {
        item: {
            type: Object as PropType<IBlogItem>,
            default: undefined,
        },
    },

    methods: {
        onTextMousedown(): void {
            if (this.$store.state.user.user) {
                this.showFullContent = true
            }
        },

        getItemSummary(content: string): string | undefined {
            return content.length < this.itemSummaryMaxChars
                ? content
                : `${content.slice(0, this.itemSummaryMaxChars)}...`
        },

        async confirmDelete(): Promise<void> {
            let shouldDelete = false

            try {
                shouldDelete = await this.$bvModal.msgBoxConfirm(
                    'Weet je zeker dat je dit wilt verwijderen?',
                    {
                        okTitle: 'Ja, ik weet het heel zeker',
                        okVariant: 'danger',
                        cancelTitle: 'Nee, laat maar zitten',
                        centered: true,
                    }
                )
            } catch {
                // shouldDelete is initialized at false
            }

            if (shouldDelete) {
                this.$emit('delete', this.item)
            }
        },
    },

    computed: {
        formattedDate(): string | undefined {
            const field = this.item?.createdAt

            if (!field) return undefined

            return new Date(field).toLocaleDateString(this.preferredLocale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })
        },

        preferredLocale(): string {
            return this.$store.state.config.preferredLocale
        },

        textContent(): string | undefined {
            return this.showFullContent
                ? this.item.content
                : this.getItemSummary(this.item.content)
        },

        showFullText(): boolean {
            return (
                this.getItemSummary(this.item.content)?.length !==
                    this.item.content.length || this.item.extraImages.length > 0
            )
        },
    },
})
