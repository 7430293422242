import { AxiosError } from 'axios'

import IApiResource from '@/types/api/IApiResource.ts'
import TypedVue from '@/config/vue.ts'
import EditableSectionText from '@/components/site/editables/EditableSectionText.vue'
import PreviewImageInput from '@/components/site/shared/PreviewImageInput.vue'
import HttpStatusCodes from '@/enums/HttpStatusCodes.ts'

interface IData extends IDataOverride {
    items: Array<IApiResource>
    totalItems: number
    currentSlide: number
    formData: {
        [key: string]: string | undefined
    }
}

export interface IDataOverride {
    endpoint: string
    createModalId: string
}

export default TypedVue.extend({
    name: 'EditableSlideShow',

    components: {
        PreviewImageInput,
        EditableSectionText,
    },

    data(): IData {
        return {
            items: [],
            currentSlide: 0,
            totalItems: 0,

            formData: {},
            endpoint: '',
            createModalId: '',
        }
    },

    async created(): Promise<void> {
        await this.refreshItems()
    },

    watch: {
        async currentSlide(newSlide, oldSlide): Promise<void> {
            if (newSlide < oldSlide) {
                return
            }

            const slideIndexToPageOffset = 2
            const nextPage = newSlide + slideIndexToPageOffset

            if (nextPage > this.totalItems || this.items.length >= nextPage) {
                return
            }

            const parameters = new URLSearchParams({
                itemsPerPage: '1',
                page: nextPage,
                'order[id]': 'desc',
                'order[createdAt]': 'desc',
            })

            const { data } = await this.$api
                .anonymous()
                .get(`${this.endpoint}?${parameters.toString()}`, {
                    headers: {
                        'Accept-Language': this.preferredLocale,
                    },
                })

            this.items.push(data['hydra:member'][0])
        },

        async preferredLocale(locale: string): Promise<void> {
            const parameters = new URLSearchParams({
                itemsPerPage: String(this.items.length),
                'order[id]': 'desc',
                'order[createdAt]': 'desc',
            })

            const { data } = await this.$api
                .anonymous()
                .get(`${this.endpoint}?${parameters.toString()}`, {
                    headers: {
                        'Accept-Language': locale,
                    },
                })

            this.items = data['hydra:member']
        },
    },

    computed: {
        preferredLocale(): string {
            return this.$store.state.config.preferredLocale
        },
    },

    methods: {
        slide(direction: string): void {
            const { carousel }: any = this.$refs

            carousel[direction]()
        },

        async refreshItems(): Promise<void> {
            const parameters = new URLSearchParams({
                itemsPerPage: '2',
                'order[id]': 'desc',
                'order[createdAt]': 'desc',
            })

            const { data } = await this.$api
                .anonymous()
                .get(`${this.endpoint}?${parameters.toString()}`, {
                    headers: {
                        'Accept-Language': this.preferredLocale,
                    },
                })

            this.totalItems = data['hydra:totalItems']
            this.items = data['hydra:member']

            this.currentSlide = 0
        },

        async deleteItem(item: IApiResource): Promise<void> {
            try {
                await this.$api.authenticated().delete(item['@id'])

                await this.refreshItems()

                this.$bvToast.toast('Item verwijderd!', {
                    title: 'Verwijderd',
                    variant: 'success',
                })
            } catch {
                this.$bvToast.toast(
                    'Ik kon dit niet verwijderen. Vraag Alex om hulp.',
                    {
                        title: 'Kon niet verwijderen',
                        variant: 'danger',
                    }
                )
            }
        },

        async post(): Promise<void> {
            try {
                await this.storeNewItem()

                this.$bvModal.hide(this.createModalId)
                this.$bvToast.toast(
                    'Item opgeslagen. Vergeet je niet de vertaling?',
                    {
                        title: 'Opgeslagen',
                        variant: 'success',
                    }
                )
                this.formData = {}
            } catch (error) {
                this.handlerPostError(error)
            }
        },

        async storeNewItem(): Promise<void> {
            const response = await this.$api
                .authenticated()
                .post(this.endpoint, this.formData, {
                    headers: {
                        'Accept-Language': this.preferredLocale,
                    },
                })
            const data = response.data as unknown as IApiResource

            const promises: Array<Promise<void>> = []

            this.$store.state.config.availableLocales
                .filter(
                    (locale: string): boolean => locale !== this.preferredLocale
                )
                .forEach((locale: string): void => {
                    promises.push(
                        this.$api
                            .authenticated()
                            .patch(data['@id'], this.formData, {
                                headers: {
                                    'Accept-Language': locale,
                                },
                            })
                    )
                })

            await Promise.all(promises)

            await this.refreshItems()
        },

        handlerPostError(error: AxiosError): void {
            const message =
                error?.response?.status === HttpStatusCodes.UnprocessableEntity
                    ? 'Je bent een of meerdere velden vergeten.'
                    : 'Vraag Alex om hulp. Er is iets stuk.'

            this.$bvToast.toast(message, {
                title: 'Kon item niet opslaan',
                variant: 'danger',
            })
        },

        async update(
            index: number,
            field: string,
            content: string
        ): Promise<void> {
            try {
                const { data } = await this.$api.authenticated().patch(
                    String(this.items[index]['@id']),
                    {
                        [field]: content,
                    },
                    {
                        headers: {
                            'Accept-Language': this.preferredLocale,
                        },
                    }
                )

                TypedVue.set(this.items, index, data)
            } catch {
                this.$bvToast.toast(
                    'Item kon niet worden aangepast. Vraag Alex om hulp.',
                    {
                        title: 'Fout',
                        variant: 'danger',
                    }
                )
            }
        },
    },
})
