









import EditableText from '@/components/site/editables/EditableText.vue'
import EditableSectionField from '@/components/site/editables/EditableSectionField.ts'

export default EditableSectionField.extend({
    name: 'EditableSectionText',
    components: { EditableText },

    props: {
        element: {
            type: String,
            required: true,
        },

        multiline: {
            type: Boolean,
            default: false,
        },
    },
})
