import { Module } from 'vuex'

import IState from '@/store/modules/auth/IState.ts'
import IRootState from '@/store/IRootState.ts'
import actions from '@/store/modules/auth/actions.ts'

const auth: Module<IState, IRootState> = {
    namespaced: true,
    actions,
}

export default auth
