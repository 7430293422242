



















































































































import TypedVue from '@/config/vue.ts'
import EditableSectionText from '@/components/site/editables/EditableSectionText.vue'
import EditableSectionImage from '@/components/site/editables/EditableSectionImage.vue'
import HttpStatusCodes from '@/enums/HttpStatusCodes.ts'

interface IData {
    labels: {
        [key: string]: {
            'nl-NL': string
            'en-US': string
        }
    }
    formData: {
        name: string
        email: string
        message: string
    }
    processing: boolean
}

export default TypedVue.extend({
    name: 'Contact',
    components: { EditableSectionImage, EditableSectionText },

    computed: {
        preferredLocale(): string {
            return this.$store.state.config.preferredLocale
        },
    },

    data(): IData {
        return {
            labels: {
                name: {
                    'nl-NL': 'Naam',
                    'en-US': 'Name',
                },

                email: {
                    'nl-NL': 'E-mailadres',
                    'en-US': 'Email address',
                },

                message: {
                    'nl-NL': 'Bericht',
                    'en-US': 'Message',
                },
            },

            formData: {
                email: '',
                name: '',
                message: '',
            },

            processing: false,
        }
    },

    methods: {
        async onSubmit(): Promise<void> {
            this.processing = true

            try {
                await this.$api
                    .anonymous()
                    .post('/api/contact_requests', this.formData)

                this.$bvToast.toast(
                    this.preferredLocale === 'nl-NL'
                        ? 'Bedankt! Uw bericht is verzonden.'
                        : 'Thank you! Your message has been sent.',
                    {
                        variant: 'success',
                    }
                )

                this.formData = {
                    email: '',
                    name: '',
                    message: '',
                }
            } catch (error) {
                if (
                    error.response?.status ===
                    HttpStatusCodes.UnprocessableEntity
                ) {
                    this.$bvToast.toast(
                        this.preferredLocale === 'nl-NL'
                            ? 'Niet alle velden zijn juist ingevuld. Probeer het opnieuw.'
                            : 'Not all fields were filled in correctly. Please try again.',
                        {
                            variant: 'danger',
                        }
                    )

                    return
                }

                this.$bvToast.toast(
                    this.preferredLocale === 'nl-NL'
                        ? 'We konden uw bericht niet verzenden. Probeer het later nog eens.'
                        : 'We were unable to send your message. Please try again later.',
                    {
                        variant: 'danger',
                    }
                )
            } finally {
                this.processing = false
            }
        },
    },
})
