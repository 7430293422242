import Vuex, { Store } from 'vuex'
import Vue from 'vue'
import store from '@keicreations/sapphire-vue/store/index'

import IRootState from '@/store/IRootState.ts'
import nav from '@/store/modules/nav/index.ts'
import auth from '@/store/modules/auth/index.ts'
import sections from '@/store/modules/sections/index.ts'
import filePicker from '@/store/modules/filePicker/index.ts'
import config from '@/store/modules/config/index.ts'

Vue.use(Vuex)

const typedStore: Store<IRootState> = store

typedStore.registerModule('nav', nav)
typedStore.registerModule('auth', auth)
typedStore.registerModule('sections', sections)
typedStore.registerModule('filePicker', filePicker)
typedStore.registerModule('config', config)

export default typedStore
