import { BToast } from 'bootstrap-vue'
import { RawLocation } from 'vue-router'

import TypedVue from '@/config/vue.ts'
import IRouteAccessControl from '@/types/IRouteAccessControl.ts'

export default TypedVue.extend({
    computed: {
        loggedIn(): boolean {
            return this.$store.state.user.userId !== null
        },

        toasts(): Array<BToast> {
            return this.$store.state.toasts.toasts
        },
    },

    watch: {
        $route(): void {
            this.handleAccessControl(this.loggedIn)
        },

        loggedIn(): void {
            this.handleAccessControl(this.loggedIn)
        },

        toasts(toasts): void {
            toasts.entries().forEach((toast: BToast, index: number): void => {
                if (toast.read) {
                    return
                }

                this.$root.$bvToast.toast(toast.message, toast)
                void this.$store.dispatch('toasts/markToastRead', index)
            })
        },
    },

    async created(): Promise<void> {
        try {
            void (await this.$store.dispatch('user/loadToken'))
            this.handleAccessControl(true)
        } catch {
            this.handleAccessControl(false)
        }

        void (await this.$store.dispatch('user/loadRefreshToken'))
    },

    methods: {
        handleAccessControl(isLoggedIn: boolean): void {
            const { currentRoute } = this.$router

            const accessControl = currentRoute.meta?.accessControl

            if (accessControl === undefined) {
                return
            }

            this.validateRouteAccessControls(accessControl)

            if (
                accessControl.anonymous &&
                !accessControl.authenticated &&
                isLoggedIn
            ) {
                this.redirectToRoute('', accessControl.redirectTo)
            } else if (
                accessControl.authenticated &&
                !accessControl.anonymous &&
                !isLoggedIn
            ) {
                this.redirectToRoute('/login', accessControl.redirectTo)
            } else {
                // no action in this clause
            }
        },

        redirectToRoute(defaultRoute: string, toRoute?: string): void {
            if (toRoute) {
                this.replaceRoute(toRoute)
            } else {
                this.replaceRoute(defaultRoute)
            }
        },

        validateRouteAccessControls(accessControl: IRouteAccessControl): void {
            if (!accessControl.anonymous && !accessControl.authenticated) {
                throw new Error(
                    'Access control cannot be disabled for both anonymous and authenticated.'
                )
            }
        },

        replaceRoute(route: RawLocation): void {
            if (this.$router.currentRoute.path !== route) {
                void this.$router.replace(route)
            }
        },
    },
})
