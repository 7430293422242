import { GetterTree } from 'vuex'

import ISiteSection from '@/types/api/ISiteSection.ts'
import RootState from '@/store/IRootState.ts'
import IState from '@/store/modules/sections/IState.ts'

const getters: GetterTree<IState, RootState> = {
    getByType:
        (state: IState) =>
        (sectionType: string): ISiteSection | undefined =>
            state.sections.find(
                (item): boolean => item['@type'] === sectionType
            ),
}

export default getters
