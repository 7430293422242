










import TypedVue from '@/config/vue.ts'
import EditableSectionText from '@/components/site/editables/EditableSectionText.vue'

export default TypedVue.extend({
    name: 'EditableNavigationItem',
    components: { EditableSectionText },

    props: {
        section: {
            type: String,
            required: true,
        },
    },

    methods: {
        onClick(): void {
            if (!this.$store.state.user.user) {
                this.$emit('navigate')
            }
        },
    },
})
