import { ActionTree } from 'vuex'
import api from '@keicreations/sapphire-vue/lib/api-platform'

import IRootState from '@/store/IRootState.ts'
import IState from '@/store/modules/auth/IState.ts'

const actions: ActionTree<IState, IRootState> = {
    async login(context, payload): Promise<void> {
        try {
            const response = await api.anonymous().post('/api/login_check', {
                email: payload.username,
                password: payload.password,
            })

            await context.dispatch('user/setToken', response.data.token, {
                root: true,
            })
            await context.dispatch(
                'user/setRefreshToken',
                response.data.refresh_token,
                {
                    root: true,
                }
            )
        } catch (error) {
            void context.dispatch('user/setToken', undefined, {
                root: true,
            })
            void context.dispatch('user/setRefreshToken', undefined, {
                root: true,
            })

            throw error
        }
    },
}

export default actions
