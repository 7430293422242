






















































































import EditableSlideShow, {
    IDataOverride,
} from '@/components/site/editables/EditableSlideShow.ts'
import PortfolioItem from '@/components/site/shared/PortfolioItem.vue'

export default EditableSlideShow.extend({
    name: 'PortfolioSection',
    components: { PortfolioItem },

    data(): IDataOverride {
        return {
            endpoint: '/api/portfolio_items',
            createModalId: 'create-portfolio-item-modal',
        }
    },

    watch: {
        currentSlide(currentSlide): void {
            if (currentSlide === this.totalItems) {
                this.currentSlide = 0
            }
        },
    },
})
