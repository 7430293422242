

















import CountryFlag from 'vue-country-flag'

import TypedVue from '@/config/vue.ts'

export default TypedVue.extend({
    name: 'CountryChooser',
    components: { CountryFlag },

    computed: {
        preferredLocale(): string {
            return this.$store.state.config.preferredLocale
        },

        availableLocales(): Array<string> {
            return this.$store.state.config.availableLocales.filter(
                (item): boolean => item !== this.preferredLocale
            )
        },
    },

    methods: {
        async selectLocale(locale: string): Promise<void> {
            this.$store.commit('config/setPreferredLocale', locale)
            await this.$store.dispatch('sections/fetchAll')
        },

        getCountry(locale: string): string {
            switch (locale) {
                case 'nl-NL':
                    return 'nl'
                case 'en-US':
                    return 'us'

                default:
                    throw new Error(`Unknown locale${locale}`)
            }
        },
    },
})
