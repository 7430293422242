import TypedVue from '@/config/vue.ts'

export default TypedVue.extend({
    computed: {
        content(): string {
            return this.$store.getters['sections/getByType'](this.section)?.[
                this.property
            ]
        },
    },

    methods: {
        async save(content: any): Promise<void> {
            await this.$store.dispatch('sections/update', {
                section: this.section,
                property: this.property,
                content,
            })
        },
    },

    props: {
        section: {
            type: String,
            required: true,
        },

        property: {
            type: String,
            required: true,
        },
    },
})
