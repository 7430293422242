import Vue from 'vue'
import Router from 'vue-router'

import routes from '@/config/routes.ts'

const router = new Router({
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    mode: 'history',
})

Vue.use(Router)

export default router
