import { Module } from 'vuex'

import state from '@/store/modules/nav/state.ts'
import mutations from '@/store/modules/nav/mutations.ts'
import IState from '@/store/modules/nav/IState.ts'
import RootState from '@/store/IRootState.ts'

const nav: Module<IState, RootState> = {
    namespaced: true,
    state,
    mutations,
}

export default nav
