import IState from '@/store/modules/config/IState.ts'

const state: IState = {
    preferredLocale:
        localStorage.getItem('preferredLocale') ?? window.navigator.language,

    availableLocales: ['nl-NL', 'en-US'],
    isMaintenanceModeActive: true,
    settingsIri: undefined,
}

export default state
