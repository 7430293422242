























import TypedVue from '@/config/vue.ts'

export default TypedVue.extend({
    name: 'Maintenance',
})
