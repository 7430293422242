import { Module } from 'vuex'

import state from '@/store/modules/filePicker/state.ts'
import mutations from '@/store/modules/filePicker/mutations.ts'
import IState from '@/store/modules/filePicker/IState.ts'
import RootState from '@/store/IRootState.ts'
import actions from '@/store/modules/filePicker/actions.ts'

const filePicker: Module<IState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
}

export default filePicker
