import { ActionTree } from 'vuex'

import IRootState from '@/store/IRootState.ts'
import IState from '@/store/modules/filePicker/IState.ts'

const actions: ActionTree<IState, IRootState> = {
    pickFiles(context, payload): void {
        context.commit('setIsModalActive', true)
        context.commit('setCallback', payload.callback)
        context.commit('setIsMultiple', payload.multiple ?? false)
    },
}

export default actions
