




import { PropType } from 'vue'

import EditableSectionField from '@/components/site/editables/EditableSectionField.ts'
import FilePickerModal from '@/components/site/modals/FilePickerModal.vue'
import EditableImage from '@/components/site/editables/EditableImage.vue'

export default EditableSectionField.extend({
    name: 'EditableSectionImage',
    components: { EditableImage, FilePickerModal },

    props: {
        size: {
            type: String as PropType<'small' | 'medium' | 'large' | 'original'>,
            required: true,
        },
    },
})
