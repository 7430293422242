import { Module } from 'vuex'

import getters from '@/store/modules/sections/getters.ts'
import state from '@/store/modules/sections/state.ts'
import mutations from '@/store/modules/sections/mutations.ts'
import IState from '@/store/modules/sections/IState.ts'
import RootState from '@/store/IRootState.ts'
import actions from '@/store/modules/sections/actions.ts'

const content: Module<IState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

export default content
