import { ActionTree } from 'vuex'
import api from '@keicreations/sapphire-vue/lib/api-platform'

import IRootState from '@/store/IRootState.ts'
import IState from '@/store/modules/config/IState.ts'

const actions: ActionTree<IState, IRootState> = {
    async loadSettings(context): Promise<void> {
        const response = await api.anonymous().get('/api/settings')
        const settings = response.data['hydra:member'][0]

        context.commit('setSettingsIri', settings['@id'])

        context.commit(
            'setIsMaintenanceModeActive',
            settings.isMaintenanceModeActive
        )
    },

    async toggleMaintenanceMode(context): Promise<void> {
        const isActive = !context.state.isMaintenanceModeActive

        await api.authenticated().patch(context.state.settingsIri, {
            isMaintenanceModeActive: isActive,
        })
        context.commit('setIsMaintenanceModeActive', isActive)
    },
}

export default actions
