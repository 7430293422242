




import Vue from 'vue'
import sapphireApp from '@keicreations/sapphire-vue/mixins/sapphire-app.js'

export default Vue.extend({
    name: 'JJPuppets',
    mixins: [sapphireApp],
})
