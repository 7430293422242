














































import { PropType } from 'vue'

import IPortfolioItem from '@/types/api/IPortfolioItem.ts'
import TypedVue from '@/config/vue.ts'
import EditableImage from '@/components/site/editables/EditableImage.vue'
import EditableText from '@/components/site/editables/EditableText.vue'

export default TypedVue.extend({
    name: 'PortfolioItem',

    components: { EditableImage, EditableText },

    props: {
        item: {
            type: Object as PropType<IPortfolioItem>,
            default: undefined,
        },
    },

    methods: {
        async confirmDelete(): Promise<void> {
            let shouldDelete = false

            try {
                shouldDelete = await this.$bvModal.msgBoxConfirm(
                    'Weet je zeker dat je dit wilt verwijderen?',
                    {
                        okTitle: 'Ja, ik weet het heel zeker',
                        okVariant: 'danger',
                        cancelTitle: 'Nee, laat maar zitten',
                        centered: true,
                    }
                )
            } catch {
                // shouldDelete is initialized at false
            }

            if (shouldDelete) {
                this.$emit('delete', this.item)
            }
        },
    },
})
