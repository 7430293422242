import { MutationTree } from 'vuex'
import Vue from 'vue'

import IState from '@/store/modules/sections/IState.ts'

const mutations: MutationTree<IState> = {
    set(state: IState, payload): void {
        state.sections = payload
    },

    updateItem(state: IState, payload): void {
        const index = state.sections.findIndex(
            (section): boolean => section['@id'] === payload['@id']
        )

        Vue.set(state.sections, index, payload)
    },
}

export default mutations
