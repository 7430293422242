import { Module } from 'vuex'

import actions from '@/store/modules/config/actions.ts'
import state from '@/store/modules/config/state.ts'
import mutations from '@/store/modules/config/mutations.ts'
import IState from '@/store/modules/config/IState.ts'
import RootState from '@/store/IRootState.ts'

const config: Module<IState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
}

export default config
