



















































































import IUser from '@/types/api/IUser.ts'
import LocaleChooser from '@/components/site/shared/LocaleChooser.vue'
import EditableNavigationItem from '@/components/site/editables/EditableNavigationItem.vue'
import TypedVue from '@/config/vue.ts'
import EditableSectionText from '@/components/site/editables/EditableSectionText.vue'

interface IData {
    opacityActive: number
    opacityInactive: number
    scrollBreakpoint: number
}

export default TypedVue.extend({
    name: 'Navigation',

    components: {
        EditableNavigationItem,
        EditableSectionText,
        LocaleChooser,
    },

    data(): IData {
        return {
            opacityActive: 1,
            opacityInactive: 0.8,
            scrollBreakpoint: 50,
        }
    },

    computed: {
        navigationElement(): any {
            // @ts-expect-error -- linter does not understand refs
            return this.$refs.nav.$el
        },

        user(): IUser | undefined {
            return this.$store.state.user.user
        },

        isMaintenanceModeActive(): boolean {
            return this.$store.state.config.isMaintenanceModeActive
        },
    },

    created(): void {
        window.addEventListener('scroll', (): void => {
            if (document.documentElement.scrollTop > this.scrollBreakpoint) {
                document.querySelector('body')!.classList.add('scrolling')
                this.navigationElement.style.opacity = this.opacityInactive
            } else if (
                document.documentElement.scrollTop <= this.scrollBreakpoint
            ) {
                document.querySelector('body')!.classList.remove('scrolling')
                this.navigationElement.style.opacity = this.opacityActive
            } else {
                // Do nothing
            }
        })
    },

    methods: {
        onHover(isHovering: boolean): void {
            if (document.documentElement.scrollTop <= this.scrollBreakpoint) {
                return
            }

            this.navigationElement.style.opacity = isHovering
                ? this.opacityActive
                : this.opacityInactive
        },

        async toggleMaintenanceMode(): Promise<void> {
            try {
                await this.$store.dispatch('config/toggleMaintenanceMode')

                this.$bvToast.toast('De instelling is opgeslagen!', {
                    title: 'Wijziging aangebracht',
                    variant: 'success',
                })
            } catch {
                this.$bvToast.toast(
                    'Kon de instelling niet aanpassen. Vraag Alex om hulp.',
                    {
                        title: 'Kon niet opslaan',
                        variant: 'danger',
                    }
                )
            }
        },

        async logout(): Promise<void> {
            await this.$store.dispatch('user/clear')
            this.$bvToast.toast('Je bent uitgelogd!', {
                title: 'Uitgelogd',
                variant: 'success',
            })
        },

        scrollIntoView(href: string): void {
            const element = document.querySelector(href)

            if (!element) {
                throw new Error(`Element "${href}" does not exist.`)
            }

            this.$smoothScroll({
                scrollTo: element,
                offset: -73,
                hash: href,
            })
        },
    },
})
