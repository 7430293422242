import { MutationTree } from 'vuex'

import IState from '@/store/modules/config/IState.ts'

const mutations: MutationTree<IState> = {
    setPreferredLocale(state: IState, payload): void {
        localStorage.setItem('preferredLocale', payload)
        state.preferredLocale = payload
    },

    setIsMaintenanceModeActive(state: IState, payload): void {
        state.isMaintenanceModeActive = payload
    },

    setSettingsIri(state: IState, payload): void {
        state.settingsIri = payload
    },
}

export default mutations
