import Vue, { VueConstructor } from 'vue'
import { Store } from 'vuex'

import IRootState from '@/store/IRootState.ts'

abstract class TypedVueClass extends Vue {
    public $store!: Store<IRootState>
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- TypedVue should be PascalCase as it is a "class"
const TypedVue = Vue as VueConstructor<TypedVueClass>

export default TypedVue
