














































































































import BlogItem from '@/components/site/shared/BlogItem.vue'
import EditableSlideShow, {
    IDataOverride,
} from '@/components/site/editables/EditableSlideShow.ts'

export default EditableSlideShow.extend({
    name: 'BlogSection',

    data(): IDataOverride {
        return {
            endpoint: '/api/blog_items',
            createModalId: 'create-blog-item-modal',
        }
    },

    components: {
        BlogItem,
    },
})
