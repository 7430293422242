import { ActionTree } from 'vuex'
import api from '@keicreations/sapphire-vue/lib/api-platform'

import IRootState from '@/store/IRootState.ts'
import IState from '@/store/modules/sections/IState.ts'

const actions: ActionTree<IState, IRootState> = {
    async fetchAll(context): Promise<void> {
        const response = await api.anonymous().get('/api/site_sections', {
            headers: {
                'Accept-Language': context.rootState.config.preferredLocale,
            },
        })

        context.commit('set', response.data['hydra:member'])
    },

    async update(
        context,
        { section: sectionName, property, content }
    ): Promise<void> {
        const section = context.getters.getByType(sectionName)

        if (!section) {
            throw new Error(`Section ${section} not found.`)
        }

        const response = await api.authenticated().patch(
            section['@id'],
            {
                [property]: content,
            },
            {
                headers: {
                    'Accept-Language': context.rootState.config.preferredLocale,
                },
            }
        )

        context.commit('updateItem', response.data)
    },
}

export default actions
