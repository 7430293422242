import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/fontawesome-free-brands'
import Vue from 'vue'

// @ts-expect-error -- faFacebook is configured incorrectly.
library.add(fad, far, fas, fal, faFacebook, faInstagram)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
