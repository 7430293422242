import Login from '@/components/Login.vue'
import IRouteConfigSingleView from '@/types/IRouteConfigSingleView.ts'
import SiteLayout from '@/components/site/Layout.vue'

const routes: Array<IRouteConfigSingleView> = [
    {
        path: '/',
        component: SiteLayout,

        meta: {
            breadcrumb: 'Home',

            accessControl: {
                anonymous: true,
                authenticated: true,
            },
        },
    },
    {
        path: '/login',
        component: Login,
        name: 'Login',

        meta: {
            breadcrumb: 'Login',

            accessControl: {
                anonymous: true,
                authenticated: false,
            },
        },
    },
    {
        path: '*',
        redirect: '/',

        meta: {
            breadcrumb: '',

            accessControl: {
                anonymous: true,
                authenticated: true,
            },
        },
    },
]

export default routes
