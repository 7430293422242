


























import TypedVue from '@/config/vue.ts'
import IMediaObject from '@/types/api/IMediaObject.ts'

interface IData {
    selectedImages: Array<IMediaObject>
}

export default TypedVue.extend({
    name: 'PreviewImageInput',

    data(): IData {
        return {
            selectedImages: [],
        }
    },

    methods: {
        async select(): Promise<void> {
            await this.$store.dispatch('filePicker/pickFiles', {
                callback: async (
                    selected: Array<string> | string
                ): Promise<void> => {
                    this.selectedImages = []

                    let selectedIris: Array<string> = []

                    selectedIris =
                        typeof selected === 'string' ? [selected] : selected

                    // eslint-disable-next-line no-loops/no-loops -- need to be in sequence
                    for (const iri of selectedIris) {
                        // eslint-disable-next-line no-await-in-loop -- need to be in sequence
                        const response = await this.$api
                            .authenticated()
                            .get(iri)

                        this.selectedImages.push(response.data)
                    }

                    console.log('hier', this.selectedImages)
                },

                multiple: this.multiple,
            })
        },
    },

    watch: {
        selectedImages(selectedImages: Array<IMediaObject>): void {
            console.log(selectedImages)

            // eslint-disable-next-line no-undef-init -- yes undef init
            let data = undefined

            if (selectedImages.length === 0) {
                data = this.multiple ? [] : undefined
            } else {
                data = this.multiple
                    ? selectedImages.map((image): string => image['@id'])
                    : selectedImages[0]['@id']
            }

            this.$emit('input', data)
        },
    },

    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
    },
})
