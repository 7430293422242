import Vue, { VNode } from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vue2SmoothScroll from 'vue2-smooth-scroll'

import store from '@/store/index.ts'
import router from '@/config/router.ts'
import { ISapphireVueApi, SapphireGuard } from '@/config/sapphire.ts'
import App from '@/App.vue'

import '@/config/font-awesome.ts'
import '@/config/vue-breadcrumbs.ts'

Vue.use(BootstrapVue)
Vue.use(Vue2SmoothScroll)

Vue.config.productionTip = false

declare module 'vue/types/vue' {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- interface has to be called "Vue".
    interface Vue {
        $api: ISapphireVueApi
    }
}

new Vue({
    mixins: [SapphireGuard],
    router,
    store,
    render: (render): VNode => render(App),
}).$mount('#app')
