









































import IHeroSection from '@/types/api/IHeroSection.ts'
import EditableSectionText from '@/components/site/editables/EditableSectionText.vue'
import TypedVue from '@/config/vue.ts'
// @ts-expect-error -- it's a fucking image, fuck off
import placeholder from '@/assets/img/placeholder.jpg'

export default TypedVue.extend({
    name: 'Hero',
    components: { EditableSectionText },

    computed: {
        pageContent(): IHeroSection | undefined {
            return this.$store.getters['sections/getByType']('HeroSection')
        },

        backgroundImage(): string {
            return this.pageContent?.image?.content?.original ?? placeholder
        },
    },

    methods: {
        async onClickEditImage(): Promise<void> {
            await this.$store.dispatch('filePicker/pickFiles', {
                callback: async (mediaObjectIri: string): Promise<void> => {
                    await this.$store.dispatch('sections/update', {
                        section: 'HeroSection',
                        property: 'image',
                        content: mediaObjectIri,
                    })
                    this.$bvToast.toast('De wijziging is opgeslagen.', {
                        title: 'Wijziging doorgevoerd.',
                        variant: 'success',
                    })
                },
            })
        },
    },
})
